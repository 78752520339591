.App {
  text-align: center;
  flex-direction: column;
  min-height: 100vh;
}

@font-face {
  font-family: "BlackJack";
  src: local("BlackJack"), url("./fonts/BlackJackFonts.ttf") format("truetype");
}

button.button-link {
  padding: 0;
}

:-webkit-any-link {
  color: rgba(0, 0, 0, 0.88);
  text-decoration: none;
}

:-moz-any-link {
  color: rgba(0, 0, 0, 0.88);
  text-decoration: none;
}

:any-link {
  color: rgba(0, 0, 0, 0.88);
  text-decoration: none;
}
