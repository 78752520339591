/* The CSS in this style tag is based off of Bear Blog's default CSS.
https://github.com/HermanMartinus/bearblog/blob/297026a877bc2ab2b3bdfbd6b9f7961c350917dd/templates/styles/blog/default.css
License MIT: https://github.com/HermanMartinus/bearblog/blob/master/LICENSE.md */

:root {
  --accent: #2337ff;
  --accent-dark: #000d8a;
  --black: 15, 18, 25;
  --gray: 96, 115, 159;
  --gray-light: 229, 233, 240;
  --gray-dark: 34, 41, 57;
  --gray-gradient: rgba(var(--gray-light), 50%), #fff;
  --box-shadow: 0 2px 6px rgba(var(--gray), 25%),
    0 8px 24px rgba(var(--gray), 33%), 0 16px 32px rgba(var(--gray), 33%);
}

strong,
b {
  font-weight: 700;
}
p {
  margin: 1.5rem 0;
}
.prose p {
  margin-bottom: 2em;
}
textarea {
  width: 100%;
  font-size: 16px;
}
input {
  font-size: 16px;
}
table {
  width: 100%;
}
.blog-post img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}
code {
  padding: 2px 5px;
  background-color: #ffd09c;
  border-radius: 2px;
  color: #703b03;
}
pre {
  padding: 1em;
  border-radius: 4px;

  background-color: #24292e;
  color: #e1e4e8;
  overflow-x: auto;
  font-size: 1rem;
  line-height: 1.5;
}
pre > code {
  all: unset;
}
blockquote {
  border-left: 4px solid #719894;
  padding: 0 0 0 20px;
  margin: 0px;
  font-size: 1em;
}
hr {
  border: none;
  border-top: 1px solid rgb(var(--gray-light));
}
mark {
  background-color: #d5cf6f;
}

/* Personal Styles */
.blog-post a {
  text-decoration: underline;
}
.blog-post a:hover {
  font-weight: 500;
}
p.date {
  margin: 0;
  font-style: italic;
}
